<template>

  <!-- Article Schedule View -->
  <j-page
    title="Publishing Schedule"
    :loading="loading"
    fill
    id="article-schedule-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Save Schedule Button -->
      <j-button
        v-if="$user.isAdmin"
        label="Save Schedule"
        icon="calendar"
        color="primary"
        :disabled="scheduleUnchanged"
        @action="handleUpdateSchedule"
      />

    </template>

    <!-- Left - Unscheduled -->
    <template #left>

      <!-- Unscheduled Heading -->
      <j-heading heading="Unscheduled" />
  
      <!-- Unscheduled Listing-->
      <ScheduleTile
        v-for="article in withoutPublicationDate"
        :key="article.tag19"
        :article="article"
        class="mb-4"
      />
  
      <!-- No Articles -->
      <j-alert v-if="withoutPublicationDate.length == 0" message="No articles to schedule." />

    </template>  

    <!-- Right - Scheduled -->
    <template #right>

      <!-- Schedules Heading -->
      <j-heading heading="Pending">
      </j-heading>

      <!-- Scheduled Listing -->
      <ScheduleTile
        v-for="article in withPublicationDate"
        :key="article.tag19"
        :article="article"
        class="mb-4"
      />

      <!-- No Articles -->
      <j-alert v-if="withPublicationDate.length == 0" message="No articles are scheduled." />

    </template>
  </j-page>
</template>

<script>

// Components
import ScheduleTile from '@/components/widgets/articles/ScheduleTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('articles')

export default {
  name: 'ArticlesSchedule',
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Publishing Schedule'
    }
  },
  // -------------
  // Components ==
  // -------------
  components: { 
    ScheduleTile
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchArticles()
    })
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'scheduled'
    ]),
    ...mapGetters([
      'loading'
    ]),
    // Helpers
    withPublicationDate () {
      return this.scheduled.where(article => article.$.published_at)
    },
    withoutPublicationDate () {
      return this.scheduled.where(article => !article.$.published_at)
    },
    scheduleUnchanged () {
      let unchanged = true
      // TODO ... there's probably an underscore function for this
      this.scheduled.getModels().forEach((model) => {
        if (model.changed()) { unchanged = false }
      })
      return unchanged
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchArticles',
      'saveSchedule'
    ]),
    // -----------
    // Handlers ==
    // -----------

    // Update schedule
    handleUpdateSchedule () {
      this.saveSchedule()
    }
  }
}
</script>
