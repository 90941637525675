<template>
  <ArticleTile :article="article">
    <template #actions>
      <!-- View Button -->
      <j-button
        icon="edit"
        type="header"
        class="float-right"
        :to="{ name: 'articles-view', params: { tag19: article.tag19 }}"
      />
    </template>

    <template
      v-if="$user.isAdmin"
      #footer
    >
      <!-- Scheduling -->
      <j-form
        :model="`articles.scheduled[${article.tag19}]`"
        class="mt-n5 mb-n3"
      >
        <!-- Publication Date -->
        <j-control
          name="published_at"
          label="Publication Date"
          class="mt-4"
        />
      </j-form>
    </template>
  </ArticleTile>
</template>

<script>

// Components
import ArticleTile from '@/components/widgets/articles/ArticleTile'

export default {
  name: 'ScheduleTile',
  // -------------
  // Components ==
  // -------------
  components: { 
    ArticleTile
  },
  // --------
  // Props ==
  // --------
  props: {
    article: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
